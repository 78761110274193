@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 8px #882372, inset 0 0 8px #882372;
    }

    50% {
        box-shadow: 0 0 16px #882372, inset 0 0 14px #882372;
    }

    100% {
        box-shadow: 0 0 8px #882372, inset 0 0 8px #882372;
    }
}

.ch {
    background-color: transparent;
    border-radius: 50px;
    box-shadow: 0 0 8px #882372, inset 0 0 8px #882372;
    -webkit-animation: pulse 2s linear 1s infinite;
    animation: pulse 2s linear 1s infinite;
}

.ch > .sc-icon {
    display: block;
    text-align: center;
    font-family: sans-serif;
    text-shadow: 0 0 4px #882372;
}